import React from "react";

import JumpToTop from "../../actions/JumpToTop.js"

const Layout = (props) => {
    const { children } = props;

    return (
        <div className="main-layout">
            <JumpToTop>
                {children}
            </JumpToTop>
        </div>

    )
}

export default Layout
