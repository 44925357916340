import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"
import { Icon, Transition } from 'semantic-ui-react'
import "../styles/ScorePage.scss"
import store from '../actions/ScoreStore.js';
import { useHistory } from "react-router-dom";

const PlayerScores = (props) => {
    const { data } = props;
    const [collapsed, setCollapsed] = useState(true);
    const expand = () => setCollapsed(!collapsed);
    const primaryAmountLimit = 45;
    const secondaryAmountLimit = 15;

    const addProgress = () => {
        let _playerMissions = [];
        data.missionSelections.forEach(function (_selection) {
            var obj = {}
            Object.assign(obj, _selection);
            obj.accruedAmount = 0;
            obj.currentPlayer = data.id;
            _playerMissions.push(obj)
        });
        return _playerMissions;
    }

    data.missionProgress = addProgress();

    const rounds = data.roundData.map((_round, index) => {
        let totalRoundScore = 0;
        let overTheLimitBy;

        return (
            <div className='round-wrapper' key={index}>
                <p className={`wh-cl ${data.id === 0 ? "one" : "two"} `}>Round {index + 1} score: {_round.roundscore}</p>
                <Transition visible={!collapsed} animation='slide down' duration={350}>
                    <div>
                        {_round.map((_mission, index) => { //map each mission
                            if (_mission.amount !== undefined) {
                                overTheLimitBy = 0;
                                let _missionAmount = parseInt(_mission.amount);

                                if (_missionAmount > 0) {
                                    const progressSoFar = data.missionProgress.find(element => element.id === _mission.id);
                                    const missionAmountLimit = _mission.id === 1 ? primaryAmountLimit : secondaryAmountLimit;
                                    let _progressAccruedAmount = parseInt(progressSoFar.accruedAmount);

                                    if (progressSoFar !== undefined) {
                                        let _newPendingAmount = _progressAccruedAmount + _missionAmount;
                                        if (_newPendingAmount > missionAmountLimit) {
                                            overTheLimitBy = _newPendingAmount - missionAmountLimit;

                                            if (overTheLimitBy > 0) {
                                                totalRoundScore = totalRoundScore + (_missionAmount - overTheLimitBy);
                                            }
                                            else {
                                                totalRoundScore = totalRoundScore + _missionAmount;
                                            }
                                            _round.roundscore = totalRoundScore;
                                            //set it back to the limit
                                            progressSoFar.accruedAmount = missionAmountLimit;
                                        } else {
                                            totalRoundScore = totalRoundScore + _missionAmount;
                                            _round.roundscore = totalRoundScore;

                                            progressSoFar.accruedAmount = _progressAccruedAmount + _missionAmount;
                                        }
                                    } else {
                                        if (progressSoFar === undefined) {
                                            console.log('NOT FOUND!', _mission.id);
                                        }
                                        totalRoundScore = totalRoundScore + _missionAmount;
                                        _round.roundscore = totalRoundScore;
                                        progressSoFar.accruedAmount = _progressAccruedAmount+ _missionAmount;
                                    }
                                }
                                else {
                                    totalRoundScore = totalRoundScore + _missionAmount;
                                    _round.roundscore = totalRoundScore;
                                }
                            }
                            return (
                                <div className='mission-wrapper' key={index}>
                                    {overTheLimitBy > 0 ?
                                        <p className='min-info'>
                                            <span className='objective'>
                                                <span>{_mission.objective} = {_mission.amount} </span>
                                                <span style={{ color: 'red' }}>({overTheLimitBy} over the limit)</span>
                                            </span>
                                        </p>
                                        :
                                        <p className='min-info'><span className='objective'>{_mission.objective} = {_mission.amount}</span></p>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </Transition>

                <hr></hr>
            </div>
        )
    })

    return (
        <div className="group-view">
            <div className='expand-rows-wrapper'>
                {collapsed
                    ?
                    <>
                        <p className='min-info'>Expand</p>
                        <Icon name='arrow circle right' onClick={() => expand()}></Icon>
                    </>
                    :
                    <>
                        <p className='min-info'>Collapse</p>
                        <Icon name='arrow circle down' onClick={() => expand()}></Icon>
                    </>
                }
            </div>
            <div>{rounds}</div>
        </div>

    )
}

const ScorePage = observer(() => {
    const [playerOneTotalScore, setPlayerOneTotalScore] = useState(0);
    const [playerTwoTotalScore, setPlayerTwoTotalScore] = useState(0);

    useEffect(() => {
        // store.pageContext = "scores";
    }, []);


    useEffect(() => {
        setPlayerOneTotalScore(store.playerOneTotalScore);
        setPlayerTwoTotalScore(store.playerTwoTotalScore);
    }, []);


    return (
        <main className="main-content scores">
            <div className='score-players-wrapper'>
                <div className='player-one wh-cl'>
                    <p>{store.players[0].playerName}</p>
                    <p className='min-info'>Total Time: {store.players[0].totalTime}</p>
                </div>
                <div className='player-two wh-cl'>
                    <p>{store.players[1].playerName}</p>
                    <p className='min-info'>Total Time: {store.players[1].totalTime}</p>
                </div>
            </div>
            <div className='score-players-wrapper'>
                <div className='total-score-text'>
                    <p>Total Score {playerOneTotalScore}</p>
                </div>
                <div className='total-score-text'>
                    <p>Total Score {playerTwoTotalScore}</p>
                </div>
            </div>
            <div className='scores-breakdown-wrapper'>
                <PlayerScores data={store.players[0]}></PlayerScores>
                <PlayerScores data={store.players[1]}></PlayerScores>
            </div>


        </main>
    )
})

export default ScorePage