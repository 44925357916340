import React from "react";
import "./styles/App.scss";

import Routes from "./components/layout/Routes";


function App() {
  return (
    <div className="App">
      <Routes></Routes>
    </div>
  );
}

export default App;