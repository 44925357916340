import React from 'react'
import { useState, useEffect } from 'react';
import { Item } from 'semantic-ui-react'
import { NavLink, useHistory } from "react-router-dom";
import { observer } from "mobx-react"

import "../styles/components/BottomBar.scss";
import ScorePage from '../pages/ScorePage';
import store from '../actions/ScoreStore.js';

const HomeButtons = (props) => {
    const { scoresVisible, setScoresVisible } = props;

    const openScores = () => {
        setScoresVisible(!scoresVisible)
    }

    return (
        <div className='bottom-button-bar'>
            <button className="restart-game-button main-button" type="button" onClick={() => openScores()}>Scores</button>
            <DisclaimerBar></DisclaimerBar>
        </div>
    )
}

const SetupButtons = observer(() => {
    const [currentPlayer, setCurrentPlayer] = useState(store.currentPlayer);

    const changePlayer = (player) => {
        store.currentPlayer = player;
        setCurrentPlayer(player);
    }

    return (
        <div className='bottom-button-bar'>
            <div className='button-wrapper'>

                {store.currentPlayer === 0 ?
                    <>

                        <div>
                            <Item as={NavLink} to='/home' className='cancel-game-button'>Back</Item>
                        </div>
                        <div>
                            <button className={`main-button ${!store.hasCompletedPlayerData ? "disabled" : " "}`}
                                onClick={() => changePlayer(1)}>Next Player</button>
                        </div>
                    </>
                    :
                    <>
                        <div>
                            <button className='main-button' onClick={() => changePlayer(0)}>Back</button>

                        </div>
                        <div>
                            <Item className={`start-game-button main-button ${!store.hasCompletedPlayerData ? "disabled" : " "}`}
                                as={NavLink} to='/confirmation'>Proceed</Item>

                        </div>
                    </>
                }
            </div>

            <DisclaimerBar></DisclaimerBar>

        </div>
    )
})


const ConfirmationButtons = () => {
    const [startEnabled, enableStart] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (store.clockType === "Stopwatch") {
            enableStart(true);
        } else {
            if (Number.isInteger(parseInt(store.clockHours)) && Number.isInteger(parseInt(store.clockMinutes))) {
                enableStart(true);
            }
            else {
                enableStart(false);
            }
        }
    }, [store]);

    const startGame = () => {

        const setupMissions = () => {

            for (let i = 0; i < 5; i++) {
                let newSelections1 = [];
                store.players[0].missionSelections.forEach(function (_selection) {
                    var obj = {}
                    Object.assign(obj, _selection);
                    obj.amount = 0;
                    obj.currentPlayer = 0;
                    obj.currentRound = i;
                    newSelections1.push(obj)
                })
                let newSelections2 = [];
                store.players[1].missionSelections.forEach(function (_selection) {
                    var obj = {}
                    Object.assign(obj, _selection);
                    obj.amount = 0;
                    obj.currentPlayer = 0;
                    obj.currentRound = i;
                    newSelections2.push(obj)
                })

                store.players[0].roundData.push(newSelections1);
                store.players[1].roundData.push(newSelections2);
            }
            return true;
        }

        let setupCompleted = setupMissions();

        if (setupCompleted) {
            history.push("/round");
        }
    }


    return (
        <div className='bottom-button-bar'>
            <div className='button-wrapper'>

                <div>
                    <Item as={NavLink} to='/setup' className='cancel-game-button'>Cancel</Item>
                </div>
                <div>
                    <button onClick={() => startGame()} to='/round' className={`start-game-button main-button ${startEnabled ? " " : "disabled"}`}>Start Game</button>
                </div>
            </div>

            <DisclaimerBar></DisclaimerBar>
        </div>
    )
}

const RoundButtons = observer((props) => {
    const { scoresVisible, setScoresVisible } = props;

    const openScores = () => {
        setScoresVisible(!scoresVisible)
    }

    const finishRound = () => {
        store.currentRound = store.currentRound + 1;
    }

    const finishGame = () => {
        store.gameFinished = true;
    }

    return (
        <div className='bottom-button-bar'>
            <div className='button-wrapper'>
                <button className="restart-game-button main-button" type="button" onClick={() => openScores()}>Scores</button>
                {store.currentRound + 1 === store.roundLimit ?
                    <button className='start-game-button main-button' onClick={() => finishGame()}>Finish Game</button>
                    :
                    <button className='next-round-button main-button' onClick={() => finishRound()}>Next Round</button>
                }
            </div>

            <DisclaimerBar></DisclaimerBar>
        </div>
    )
})

const EndOfGameButtons = () => {
    const history = useHistory();

    const goToScores = () => {

        store.pageContext = "scores";

        history.push("/scores");
    }
    return (

        <div className='bottom-button-bar'>
            <div className='button-wrapper'>

                <div className='start-wrapper'>
                    <div className='start-wrapper'>
                        <button onClick={() => goToScores()} to='/scores' className='start-game-button main-button'>Done</button>
                        <div className='min-info'>Proceed to scores!</div>
                    </div>
                </div>
            </div>

            <DisclaimerBar></DisclaimerBar>
        </div>
    )
}
const ScoreButtons = () => {
    const history = useHistory();
    const [confirmVisible, setVisible] = useState(false);

    const resetGame = () => {
        store.currentPlayer = 0;
        store.currentRound = 0;
        store.playerTwoGoesFirst = false;
        store.gameFinished = false;

        store.players = [{
            id: 0,
            playerName: 'Player 1',
            faction: null,
            totalTime: null,
            roundData: [],
            missionProgress: [],
            missionSelections: [
                {
                    id: 1,
                    objective: "PRIMARY OBJECTIVE",
                    type: "scores",
                    scores: ["5", "10", "15"]
                },
            ],
        }, {
            id: 1,
            playerName: 'Player 2',
            faction: null,
            totalTime: null,
            roundData: [],
            missionProgress: [],
            missionSelections: [
                {
                    id: 1,
                    objective: "PRIMARY OBJECTIVE",
                    type: "scores",
                    scores: ["5", "10", "15"]
                },
            ],
        }]

        return true;
    }

    const restartGame = () => {
        setVisible(!confirmVisible);
    }

    const ConfirmRestart = () => {

        const restartGame = () => {
            let finished = resetGame();

            if (finished) {
                history.push("/home");
            }
            else {
                console.log('error');
            }
        }
        const cancel = () => {
setVisible(false)
        }

        return (
            <div>

                <div>Are you sure you wish to restart?</div>
                <div className='restart-wrapper'>
                    <button className="restart-game-button main-button" type="button" onClick={restartGame}>Confirm</button>
                    <button className="cancel-restart-button main-button" type="button" onClick={cancel}>Cancel</button>
                </div>

            </div>
        )
    }

    return (
        <>
            {confirmVisible ?
                <div className='confirm-restart-overlay'>
                    <ConfirmRestart></ConfirmRestart>
                </div>
                :
                <></>
            }

            <div className='bottom-button-bar'>
                <div className='button-wrapper'>

                    <div className='restart-wrapper'>
                        <button className="restart-game-button main-button" type="button" onClick={restartGame}>Restart</button>
                    </div>
                </div>

                <DisclaimerBar></DisclaimerBar>
            </div>
        </>
    )
}

const DisclaimerBar = () => {
    return (
        <div className='disclaimer-bar'>
            <span className='min-info'>This is an early build. Please send any bug reports to <a className="hover-effect" href="mailto:contact@hammerscore.com" >contact@hammerscore.com</a>
            </span>
        </div>
    )
}

const BottomButtonBar = observer(() => {
    const [scoresVisible, setScoresVisible] = useState(false);

    return (
        <>
            {scoresVisible ?
                <div className='scores-overlay'>
                    <ScorePage></ScorePage>
                </div>

                :
                <></>
            }

            {(() => {
                switch (store.pageContext) {
                    case 'home':
                        return <HomeButtons></HomeButtons>
                    case 'setup':
                        return <SetupButtons ></SetupButtons>

                    case 'confirmation':
                        return <ConfirmationButtons></ConfirmationButtons>
                    case 'round':
                        return <RoundButtons scoresVisible={scoresVisible} setScoresVisible={setScoresVisible}></RoundButtons>
                    case 'endofgame':
                        return <EndOfGameButtons scoresVisible={scoresVisible} setScoresVisible={setScoresVisible}></EndOfGameButtons>

                    case 'scores':
                        return <ScoreButtons></ScoreButtons>
                    default:
                        return null;
                }
            })()}
        </>
    )
})

export default BottomButtonBar