export const secondaryOne = {
    id: 1,
    groupName: "PURGE THE ENEMY",
    missions: [
        {
            id: 101,
            objective: "ASSASSINATE",
            type: "scores",
            scores: ["3", "6", "9", "12", "15"]
        },
        {
            id: 102,
            objective: "BRING IT DOWN",
            type: "scorerange",
            scoremin: 2,
            scoremax: 15
        },
        {
            id: 103,
            objective: "TITAN HUNTER",
            type: "scores",
            scores: ["10", "12", "15"]
        },
        {
            id: 104,
            objective: "CUT OFF THE HEAD",
            type: "scores",
            scores: ["1", "3", "6", "10", "13"], //todo - reverse funnel scores,
            roundsAvailable: [5]
        },
        {
            id: 105,
            objective: "CODEX WARFARE",
            type: "scorerange",
            scoremin: 1,
            scoremax: 15,
            faction: "Space Marines",
            roundsAvailable: [5]
        },
    ]
}

export const secondaryTwo = {
    id: 2,
    groupName: "NO MERCY, NO RESPITE",
    missions: [
        {
            id: 201,
            objective: "THIN THEIR RANKS",
            type: "manualinput",
            divideBy: 10 
        },
        {
            id: 202,
            objective: "GRIND THEM DOWN",
            type: "check",
            score: 3
        },
        {
            id: 203,
            objective: "WHILE WE STAND, WE FIGHT",
            type: "scores",
            scores: ["5", "10", "15"],
            roundsAvailable: [5]
        },
        {
            id: 204,
            objective: "CODE OF COMBAT",
            type: "scores",
            scores: ["3", "6", "9", "12", "15"],
            faction: "Necron"
        },
        {
            id: 205,
            objective: "OATHS OF MOMENT",
            type: "scorerange",
            scoremin: 1,
            scoremax: 4,
            faction: "Space Marines"
        },
    ]
}

export const secondaryThree = {
    id: 3,
    groupName: "BATTLEFIELD SUPREMACY",
    missions: [
        {
            id: 301,
            objective: "ENGAGE ON ALL FRONTS",
            type: "scores",
            scores: ["2", "3"]
        },
        {
            id: 302,
            objective: "LINEBREAKER",
            type: "check",
            score: 4
        },
        {
            id: 303,
            objective: "DOMINATION",
            type: "check",
            score: 3
        },
        {
            id: 304,
            objective: "PURGE THE VERMIN",
            type: "scores",
            scores: ["2", "4", "6", "8"],
            faction: "Necron",
            roundsAvailable: [1,2,3,4]
        },
        {
            id: 305,
            objective: "THE TREASURES OF AEONS",
            type: "scores",
            scores: ["2", "3", "5"],
            faction: "Necron"
        },
        {
            id: 306,
            objective: "SHOCK TACTICS",
            type: "check",
            score: 3,
            faction: "Space Marines"
        },
    ]
}

export const secondaryFour = {
    id: 4,
    groupName: "SHADOW OPERATIONS",
    missions: [
        {
            id: 401,
            objective: "INVESTIGATE SITES",
            type: "check",
            score: 3
        },
        {
            id: 402,
            objective: "TELEPORT HOMER",
            type: "check",
            score: 4
        },
        {
            id: 403,
            objective: "RAISE THE BANNERS HIGH",
            type: "scorerange",
            scoremin: 1,
            scoremax: 6,
            roundsAvailable: [0,1,2,3,4,5]
        },
        
        {
            id: 404,
            objective: "DEPLOY SCRAMBLERS", //todo - per round, score only if ticked 3 times.
            type: "multicheck",
            score: 10,
            maxTimes: 3
        },
        {
            id: 405,
            objective: "ANCIENT MACHINERIES",
            type: "scores",
            scores: ["3", "6", "9", "12", "15"],
            faction: "Necron"
        },
    ]
}

export const secondaryFive = {
    id: 5,
    groupName: "WARPCRAFT",
    missions: [
        {
            id: 501,
            objective: "MENTAL INTERROGATION",
            type: "check",
            score: 3
        },
        {
            id: 502,
            objective: "PSYCHIC RITUAL", //todo - per round, score only if ticked 3 times.
            type: "multicheck",
            score: 15,
            maxTimes: 3
        },
        {
            id: 503,
            objective: "ABHOR THE WITCH",
            type: "scorerange",
            scoremin: 3,
            scoremax: 15 //todo - p e na exo kavles, 3,5,6,9-15
        },
        {
            id: 504,
            objective: "PIERCE THE VEIL",
            type: "custom", //todo - checkbox, tick in 2 rounds = 8, 4 rounds = 15. 3 rounds = 8.
        },
    ]
}

export const missionsCommon = {
    id: 101,
    groupName: "MISSION OBJECTIVES",
    missions: [
        {
            id: 1001,
            objective: "RETRIEVAL MISSION - MINIMISE LOSSES",
            type: "scores",
            scores: ["5", "10", "15"],
            roundsAvailable: [5]
        },
        {
            id: 1002,
            objective: "SCORCHED EARTH - RAZE",
            type: "scores",
            scores: ["6", "12"],
            roundsAvailable: [5]
            
        },
        {
            id: 1003,
            objective: "VITAL INTELLIGENCE - DATA INTERCEPT",
            type: "scorerange",
            scoremin: 1,
            scoremax: 6
        },
        {
            id: 1004,
            objective: "SURROUND AND DESTROY - SURROUND THEM",
            type: "scores",
            scores: ["4", "8"],
            roundsAvailable: [0,1,2,3,4,5]
        },
        {
            id: 1005,
            objective: "BATTLE LINES - VITAL GROUND",
            type: "scores",
            scores: ["2", "3", "4", "5", "7"]
        },
        {
            id: 1006,
            objective: "THE SCOURING - STRATEGIC SCAN",
            type: "scores",
            scores: ["3", "6", "10", "15"],
            roundsAvailable: [5]
        },
        {
            id: 1007,
            objective: "OVERRUN - OVERRUN",
            type: "scores",
            scores: ["2", "3", "5"]
        },
        {
            id: 1008,
            objective: "SWEEP AND CLEAR - DIRECT ASSAULT",
            type: "scores",
            scores: ["3", "5"]
        },
        {
            id: 1009,
            objective: "PRIORITY TARGET - PRIORITY TARGETS",
            type: "scores",
            scores: ["3", "5"]
        },
    ]
}

export const combinedTournament = [missionsCommon, secondaryOne, secondaryTwo, secondaryThree, secondaryFour, secondaryFive];