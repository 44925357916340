import React, { Component } from 'react'
import { Transition, Item } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";

import Scores from '../components/missions/Scores';
import ScoreRange from '../components/missions/ScoreRange';
import Check from '../components/missions/Check';
import ManualInput from '../components/missions/ManualInput';
import Boss from '../components/missions/Boss';
import Multicheck from '../components/missions/Multicheck';
import store from '../actions/ScoreStore.js';

const MissionView = (props) => {
    const { playerMissions } = props;

    return (
        <>
            {playerMissions !== undefined && playerMissions.length > 0 ? playerMissions.map((_mission) => {
                return (
                    <div className='mission-objective-wrapper' key={_mission.id}>
                        <>
                            {(() => {
                                switch (_mission.type) {
                                    case 'scores':
                                        return <Scores missionData={_mission}></Scores>
                                    case 'scorerange':
                                        return <ScoreRange missionData={_mission}></ScoreRange>
                                    case 'check':
                                        return <Check missionData={_mission}></Check>
                                    case 'manualinput':
                                        return <ManualInput missionData={_mission}></ManualInput>
                                    case 'boss':
                                        return <Boss missionData={_mission}></Boss>
                                    case 'multicheck':
                                        return <Multicheck missionData={_mission}></Multicheck>

                                    default:
                                        return null;
                                }
                            })()}
                        </>
                    </div>
                )
            })
                : <div className='error-data'>
                    <span>NO DATA - PLEASE RESTART</span>
                    <Item as={NavLink} to='/setup' className='cancel-game-button'>Restart</Item>
                </div>
            }
        </>
    )
}


export default class PlayerRoundView extends Component {
    state = { hide: 0, show: 1000 }

    render() {
        const { hide, show } = this.state

        return (

            <Transition duration={{ hide, show }} visible={this.props.visible}>
                <div className='player-2'>
                    <MissionView playerMissions={store.players[this.props.playerId].roundData[store.currentRound]}></MissionView>
                </div>
            </Transition>
        )
    }
}

