import store from '../actions/ScoreStore.js';
import { combinedTournament } from "./TournamentData"
import { combinedDefault } from "./DefaultData"


export const allMissionData = function () {

    switch (store.rulesetId) {
        case "Default":
            return combinedDefault;
        case "Tournament":
            return combinedTournament
        default:
            console.log('error')
            break;
    }

} 