export const clockOptions = [
    {
        key: 1,
        text: 'Stopwatch',
        value: 'Stopwatch',
    },
    {
        key: 2,
        value: 'Countdown',
        text: 'Countdown'
    }
];

export const rulesetOptions = [
    {
        key: 1,
        text: 'Default',
        value: 'Default',
    },
    {
        key: 2,
        value: 'Tournament',
        text: 'Tournament'
    }
];