
import React from 'react';
import { Route } from 'react-router-dom';

import ScrollToTopBtn from "../../actions/ScrollToTop";
import BottomButtonBar from '../BottomButtonBar';
import Layout from "./Layout"

const MainLayout = ({ children }) => (

    <>
        <Layout children={children}></Layout>
      <BottomButtonBar></BottomButtonBar>

        <ScrollToTopBtn />
    </>
);

const MainLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={matchProps => (
            <MainLayout>
                <Component {...matchProps} />
            </MainLayout>
        )} />
    )
};

export default MainLayoutRoute;