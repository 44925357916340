import React from 'react';
import { Route } from 'react-router-dom';

import ScrollToTopBtn from "../../actions/ScrollToTop";

const LandingLayout = ({ children, ...rest }) => {
  return (
    <>
      {children}
      
      <div className='disclaimer-top-bar'>
            <span className='min-info'>This is an early build. Please send any bug reports to <a className="hover-effect" href="mailto:contact@hammerscore.com" >contact@hammerscore.com</a>
            </span>
        </div>
      <ScrollToTopBtn />
    </>
  )
}

const LandingLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <LandingLayout>
        <Component {...matchProps} />
      </LandingLayout>
    )} />
  )
};

export default LandingLayoutRoute; 