import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"

import "../../styles/MissionComponents.scss";
import ConditionalMissionView from  "../ConditionalMissionView"

const Scores = observer((props) => {
    const { missionData } = props;
    const [scoreSelection, setSelection] = useState(missionData.amount);

    const selectScore = (newScore) => {
        let prevSelection = scoreSelection;
        if (prevSelection === newScore) {
            setSelection(null)
            missionData.amount = 0;
        }
        else {
            setSelection(newScore)
            missionData.amount = newScore;
        }
    }
        
    useEffect(() => {
        setSelection(missionData.amount);
    }, [missionData.amount]);

    const ProgressView = () => {
        return (
            <div className='score-selector-wrapper'>
                {missionData.scores.map((_mission, index) => (
                    <div className={`score-selector ${scoreSelection === _mission ? "selected" : " "}`}
                        key={index}
                        onClick={() => selectScore(_mission)}
                    >
                        {_mission}

                    </div>
                ))}
            </div>
        )
    }
    
    return (
        <div className='scores-wrapper'>
            <ConditionalMissionView missionData={missionData} ProgressView={<ProgressView/>}></ConditionalMissionView>
        </div>
    )
})

export default Scores