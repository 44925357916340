import React, { useState, useEffect } from 'react'
import store from '../actions/ScoreStore.js';
import { Dropdown } from 'semantic-ui-react'
import "../styles/ConfirmationPage.scss"
import Toggle from 'react-toggle'
import { observer } from "mobx-react"
import { clockOptions } from "../constants/MiscData.js"

const SelectionView = (props) => {
    const { selections } = props;
    const group = selections.map((group, index) => {
        return (
            <div className='group-wrapper' key={index}>
                <p className='wh-cl'>{group.objective}</p>
            </div>
        )
    })

    return (
        <div className="final-mission-choices">
            {group}
        </div>

    )
}

const PlayerView = (props) => {
    const { playerId, playerClass } = props;

    return (

        <div className={`wh-cl ${playerClass}`}>
            <h2>{store.players[playerId].playerName}</h2>
            <h2>{store.players[playerId].faction}</h2>

            <SelectionView selections={store.players[playerId].missionSelections}></SelectionView>
        </div>

    )
}


const ConfirmationPage = observer(() => {
    const [errorMsg, setError] = useState();
    const [hoursInput, setHours] = useState();
    const [minutesInput, setMinutes] = useState();
    const [clockType, setClockType] = useState();
    const [startEnabled, enableStart] = useState(false);

    useEffect(() => {
        store.pageContext = "confirmation";
    }, []);

    const playerTwoGoesFirst = (e) => {
        store.playerTwoGoesFirst = e.target.checked;
    }

    const selectedMode = (e, { value }) => {
        // e.persist();
        store.clockType = value;
        setClockType(value)
    };

    const updateHours = (newInput) => {
        setHours(newInput);
        if (Number.isInteger(parseInt(newInput))) {
            setError();
            store.clockHours = parseInt(newInput);
        }
        else {
            setError('Wrong Input!')
            store.clockHours = 0;
        }
    }

    const updateMinutes = (newInput) => {
        setMinutes(newInput);
        if (Number.isInteger(parseInt(newInput))) {
            setError();
            store.clockMinutes = parseInt(newInput);
        }
        else {
            setError('Wrong Input!')
            store.clockMinutes = 0;
        }
    }

    useEffect(() => {
        if (clockType === "Stopwatch"){
            enableStart(true);
        }else{
            if (Number.isInteger(parseInt(store.clockHours)) && Number.isInteger(parseInt(store.clockMinutes))) {
                enableStart(true);
            }
            else {
                enableStart(false);
            }
        }
    }, [hoursInput, minutesInput, clockType]);

 


    return (
        <main className="main-content confirm">
            <h1>Confirm your choices!</h1>
            <div className='confirm-players-wrapper'>
                <PlayerView playerId={0} playerClass="one"></PlayerView>
                <PlayerView playerId={1} playerClass="two"></PlayerView>
            </div>
            <div className='clock-wrapper'>
                <span>Type of Clock</span>
                <Dropdown
                    className='clock-selector main-dropdown'
                    placeholder='Select ruleset'
                    selection
                    value={store.clockType}
                    options={clockOptions}
                    onChange={selectedMode}
                />

                {store.clockType === "Countdown" ?
                    <>

                        <div>Time per player</div>
                        <div className="manual-time-input">
                            <div>
                                <label>Hours: </label>
                                <input className='manual-input-field'
                                    placeholder={'Hours'}
                                    type="text"
                                    pattern={"[0-9]*"}
                                    value={store.clockHours}
                                    onChange={e => updateHours(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Minutes: </label>
                                <input className='manual-input-field'
                                    placeholder={'Minutes'}
                                    type="text"
                                    pattern={"[0-9]*"}
                                    value={store.clockMinutes}
                                    onChange={e => updateMinutes(e.target.value)}
                                />
                            </div>

                            <div className='min-info'>{errorMsg}</div>
                        </div>
                    </>

                    :
                    <></>

                }

            </div>

            <div className='toggle-wrapper'>
                <div>{store.players[0].playerName}</div>
                <Toggle defaultChecked={false} icons={false} onChange={playerTwoGoesFirst} />
                <div>{store.players[1].playerName}</div>
            </div>

            <div className='start-wrapper'>
                {store.playerTwoGoesFirst ?
                    <div className='min-info'>{store.players[1].playerName} goes first</div>
                    :
                    <div className='min-info'>{store.players[0].playerName} goes first</div>
                }
                <div className='min-info'>The game timer will start counting immediately</div>
                
            </div>
        </main>


    )

})

export default ConfirmationPage