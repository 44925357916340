import React, { useState, useEffect } from 'react'
import { Checkbox } from 'semantic-ui-react'
import store from '../../actions/ScoreStore.js';
import { observer } from "mobx-react"
import ConditionalMissionView from "../ConditionalMissionView"

const Multicheck = observer((props) => {
    const { missionData } = props;
    const [isChecked, setChecked] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        let counter = 0;

        store.players[missionData.currentPlayer].roundData.forEach(function (_missionRound) {
            _missionRound.forEach(function (_mission) {
                if (_mission.completed) {
                    counter++;
                }
            })
        })
        if (counter >= 3) {
            setDisabled(true)
        }

    }, [missionData]);

    const missionCompleted = () => {
        setChecked(!isChecked)
        missionData.completed = !isChecked;
        if (isChecked) {
            missionData.amount = 0;
        }
        else {
            missionData.amount = missionData.endscore;
        }
    }

    const ProgressView = () => {
        return (
            <div className='check-selector-wrapper'>
                <Checkbox className='checkbox-field'
                    checked={isChecked}
                    onChange={missionCompleted}
                    disabled={isDisabled}
                ></Checkbox>
            </div>
        )
    }

    return (
        <div className='multicheck-wrapper'>
            <ConditionalMissionView missionData={missionData} ProgressView={<ProgressView />}></ConditionalMissionView>
        </div>
    )
})

export default Multicheck