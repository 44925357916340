import React from "react";
import { Switch, Redirect } from "react-router-dom";

import HomePage from "../../pages/HomePage";
import SetupPage from "../../pages/SetupPage";
import RoundPage from "../../pages/RoundPage";
import ScorePage from "../../pages/ScorePage";
import ConfirmationPage from "../../pages/ConfirmationPage";
import EndOfGamePage from "../../pages/EndOfGamePage";

import LandingLayoutRoute from "./LandingLayout";
import MainLayoutRoute from "./MainLayout";

const Routes = () => {

    return (
        <Switch>
            <LandingLayoutRoute path="/home" component={HomePage} />
            <MainLayoutRoute path="/setup" component={SetupPage} />
            <MainLayoutRoute path="/confirmation" component={ConfirmationPage} />
            <MainLayoutRoute path="/scores" component={ScorePage} />
            <MainLayoutRoute path="/endofgame" component={EndOfGamePage} />
            <MainLayoutRoute path="/round" component={RoundPage} />

            <Redirect to="/home" />
        </Switch>
    );
}
export default Routes
