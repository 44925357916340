import React, { useState, useEffect } from 'react';
import "../styles/components/Clock.scss"
import TimerMachine from "react-timer-machine";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import store from '../actions/ScoreStore.js';

momentDurationFormatSetup(moment);

const Clock = (props) => {
    const {gameFinished} = props;
    const [paused, setPaused] = useState(store.playerTwoGoesFirst);
    const [stopped, setStopped] = useState();
    const [time, setTime] = useState(1);
    const [startTime, setStartTime] = useState(0)

    useEffect(() => {
        if (gameFinished){
            setStopped(true)
            store.gameFinished = true;
        }
    }, [gameFinished]);

    useEffect(() => {
        setStartTime(store.countdownTotalTimeMS)
        setStopped(false)
    }, []);

    const timerTick = (tick) => {
        setTime(tick.ms / 10)
    }

    const swapTimer = (paused) => {
        setPaused(paused)
    }

    const getTimers = (t, id) => {
        let datetext = ''

        var d = new Date('2020', '01', '01', t.h, t.m, t.s, t.ms);
        datetext = d.toTimeString();
        datetext = datetext.split(' ')[0];
        datetext = datetext + ':' + t.ms;

        store.players[id].totalTime = datetext;
    }


    return (
        <div className="timers-bar" >
            <div className={`timer ${!paused ? "running" : " "} `}>
                <div className='timer-text'>{store.players[0].playerName}</div>
                <TimerMachine
                    started={!stopped}
                    paused={paused}
                    countdown={store.clockType === "Countdown" ? true : false}
                    interval={25}
                    timeStart={startTime * 1000}
                    onTick={time => timerTick(time)}
                    onStop={time => getTimers(time, 0)}
                />

            </div>
                <button className='main-button' onClick={() => swapTimer(!paused)}>Swap player</button>
            <div>
                <div className={`timer ${paused ? "running" : " "} `}>
                    <div className='timer-text'>{store.players[1].playerName}</div>
                    <TimerMachine
                        started={!stopped}
                        paused={!paused}
                        countdown={store.clockType === "Countdown" ? true : false}
                        interval={25}
                        timeStart={startTime * 1000}
                        onTick={time => timerTick(time)}
                        onStop={time => getTimers(time, 1)}
                    />
                </div>
            </div>
        </div>

    )
}

export default Clock;