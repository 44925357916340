import React from 'react'

const ConditionalMissionView = (props) => {
    const { missionData, ProgressView } = props;


    const RenderMission = () => {
        if (missionData.roundsAvailable) {
            //has round exclusions 
            if (missionData.roundsAvailable.includes(missionData.currentRound)) {
                //is available for this round. can also be end of game.
                return (
                    <>
                        {missionData.objective}
                        {ProgressView}
                        <hr></hr>
                    </>
                )
            }
            else if (missionData.roundsAvailable.includes(5) && missionData.roundsAvailable.length === 1) {
                //has end of game and is the only round.
                if (missionData.currentRound !== 5) {
                    //is not currently the end of game                    
                    return (
                        <>
                            {missionData.objective}
                            <div className='min-info'>Score at the end of the game</div>
                            <hr></hr>
                        </>
                    )
                }
                else {
                    //is end of game.
                    return (
                        <>
                            {missionData.objective}
                            {ProgressView}
                            <hr></hr>
                        </>
                    )
                }
            }
            else if (missionData.currentRound === 5) {
                return <></>
            }
            else {
                //is not available for this round and is not available in end game
                return (
                    <>
                        {missionData.objective}
                        <div className='min-info'>Objective not available this round</div>
                        <hr></hr>
                    </>
                )
            }
        }
        else {
            //is normal view.
            return (
                <>
                    {missionData.objective}
                    {ProgressView}
                    <hr></hr>
                </>
            )
        }
    }


    return (<RenderMission></RenderMission>)
}

export default ConditionalMissionView