import { observable, computed, decorate } from "mobx"

class ScoreStore {
    rulesetId = "Default";
    pageContext = "home";
    clockType = "Stopwatch";
    clockHours = 0;
    clockMinutes = 0;

    currentPlayer = 0;
    currentRound = 0;
    roundLimit = 5;
    playerTwoGoesFirst = false;
    gameFinished = false;
    playerOneTemp = 0;
    playerTwoTemp = 0;

    players = [{
        id: 0,
        playerName: 'Player 1',
        faction: null,
        totalTime: null,
        roundData: [],
        missionProgress: [],
        missionSelections: [
            {
                id: 1,
                objective: "PRIMARY OBJECTIVE",
                type: "scores",
                scores: ["5", "10", "15"]
            },
            // {
            //     id: 105,
            //     objective: "CODEX WARFARE",
            //     type: "scorerange",
            //     scoremin: 1,
            //     scoremax: 15,
            //     faction: "Space Marines",
            //     roundsAvailable: [5]
            // },
            // {
            //     id: 403,
            //     objective: "RAISE THE BANNERS HIGH",
            //     type: "scorerange",
            //     scoremin: 1,
            //     scoremax: 6,
            //     roundsAvailable: [0,1,2,3,4,5]
            // },
            // {
            //     id: 1002,
            //     objective: "VITAL GROUND", 
            //     type: "scorerange",
            //     scoremin: 2,
            //     scoremax: 7,
            //     roundsAvailable: [1,2,3,4,5]
            // },
        ]
    }, {
        id: 1,
        playerName: 'Player 2',
        faction: null,
        totalTime: null,
        roundData: [],
        missionProgress: [],
        missionSelections: [
            {
                id: 1,
                objective: "PRIMARY OBJECTIVE",
                type: "scores",
                scores: ["5", "10", "15"]
            },
            //uncomment for test data
            // {
            //     id: 401,
            //     objective: "INVESTIGATE SITES",
            //     type: "check",
            //     score: 3
            // },
            // {
            //     id: 1005,
            //     objective: "RAZE", 
            //     type: "scores",
            //     scores: ["5", "15"],
            //     roundsAvailable: [5]
            // },
            // {
            //     id: 204,
            //     objective: "FIRST STRIKE",
            //     type: "scores", 
            //     scores: ["5", "8"],
            //     roundsAvailable: [0]
            // },
        ],
    }]

    get currentPlayerData() {
        return this.players[this.currentPlayer];
    }

    get hasCompletedPlayerData() {
        let playerData = this.currentPlayerData;
        let missionSelections = playerData.missionSelections;
        let playerName = playerData.playerName;

        return missionSelections.length === 4 && playerName.length > 0;
    }

    get playerOneTotalScore() {
        let self = this;
        self.playerOneTemp = 0;

        this.players[0].roundData.forEach(function (_round) {
            if (_round !== undefined) {
                self.playerOneTemp = self.playerOneTemp + _round.roundscore
            }
        })
        return self.playerOneTemp;
    }

    get playerTwoTotalScore() {
        let self = this;
        self.playerTwoTemp = 0;

        this.players[1].roundData.forEach(function (_round) {
            if (_round !== undefined) {
                self.playerTwoTemp = self.playerTwoTemp + _round.roundscore;
            }
        })
        return self.playerTwoTemp;
    }

    get countdownTotalTimeMS() {
        let total = 0;

        if (this.clockType === "Countdown"){
            if (Number.isInteger(this.clockHours)){
                total = total + (this.clockHours * 3600)
            }

            if (Number.isInteger(this.clockMinutes)){
                total = total + (this.clockMinutes * 60);
            }
        }

        return total;
    }

}

decorate(ScoreStore, {
    pageContext: observable,
    currentPlayer: observable,
    currentRound: observable,
    players: observable,
    playerTwoGoesFirst: observable,
    gameFinished: observable,
    rulesetId: observable,
    playerOneTemp: observable,
    playerTwoTemp: observable,
    clockType: observable,    
    clockHours: observable,
    clockMinutes: observable,

    hasCompletedPlayerData: computed,
    currentPlayerData: computed,
    playerOneTotalScore: computed,
    playerTwoTotalScore: computed,
    countdownTotalTimeMS: computed

})

const store = new ScoreStore();

export default store;