import _ from "lodash"
import React, { useState, useEffect } from 'react'
import '../styles/SetupPage.scss';
import store from '../actions/ScoreStore.js';
import { observer } from "mobx-react"
import useFitText from "use-fit-text";


const GroupView = observer((props) => {
    const { groupData, groupClass, setErrorMsg, currentPlayer } = props;

    const [groupHasSelection, setGroupSelection] = useState(false);
    const [groupSelection, setSelection] = useState(null);

    useEffect(() => {
        const findMissions = function (playerMissions) {
            let _tempMission;
            playerMissions.forEach(function (_selection) {
                const found = groupData.missions.find(element => element.id === _selection.id);
                if (found !== undefined)
                    _tempMission = (found);
            })
            return _tempMission
        };

        let preSelected = findMissions(store.players[currentPlayer].missionSelections)
        if (preSelected !== undefined){
            setGroupSelection(true);
            setSelection(preSelected);
        }
        else{
            setGroupSelection(false);
            setSelection(null)
        }
    }, [currentPlayer, groupData]);

 
    const group = () => {
        const isDisabled = store.players[currentPlayer].missionSelections.length === 4 ? "disabled" : " ";
        const isUnavailable = store.players[currentPlayer].faction === null ? "unavailable" : " ";

        const removeFromSelection = (selection) => {
            store.players[currentPlayer].missionSelections = _.reject(store.players[currentPlayer].missionSelections, 
                function(el) { 
                    return el.id === selection.id;
                 });
        }

        const addToSelection = (selection) => {
            if (store.players[currentPlayer].missionSelections.length < 4) {
                store.players[currentPlayer].missionSelections.push(selection);
            }
        }

        const selectBlock = (newSelection) => {
            const prevSelection = groupSelection;
            if (store.players[currentPlayer].missionSelections.length === 4) {
                if (groupHasSelection) {
                    if (prevSelection === newSelection) {
                        setSelection(null);
                        setGroupSelection(false);
                        removeFromSelection(prevSelection)
                    } else {
                        setSelection(newSelection);
                        setGroupSelection(true);
                        addToSelection(newSelection);
                    }
                } else {
                    if (prevSelection === newSelection) {
                        setSelection(null);
                        setGroupSelection(false);
                        removeFromSelection(prevSelection)
                    } else {
                        setErrorMsg('De-select from another group')
                    }
                }
            } else {
                if (prevSelection === newSelection) {
                    setSelection(null);
                    setGroupSelection(false);
                    removeFromSelection(prevSelection)
                }
                else {
                    if (prevSelection) {
                        removeFromSelection(prevSelection);
                    }
                    setSelection(newSelection);
                    setGroupSelection(true);
                    addToSelection(newSelection);
                }
            }
        }
        
        return (
            <div className={`group-wrapper ${isDisabled} ${isUnavailable}`} key={groupData.id}>
                <p className={`${groupClass}`}>{groupData.groupName}</p>
                <div className={`mission-wrapper ${groupHasSelection ? "selected" : " "} `}>


                    {groupData.missions.map((_mission) => {  
                        const { fontSize, ref } = useFitText({ maxFontSize: 64, minFontSize: 10 });
                        const isActive = groupSelection ? groupSelection.id === _mission.id ? "active" : " " : " ";
                        let isVisible = "";

                        if ( _mission.faction){
                            if (_mission.faction !== store.players[currentPlayer].faction){
                                isVisible = "invisible"
                            }
                        }

                        return (
                            <span key={_mission.id} className={`${isVisible}`}  >
                                {_mission.faction ?
                                    _mission.faction === store.players[currentPlayer].faction ?
                                        <div ref={ref} style={{ fontSize, height: 55, width: 125 }} className={`mission-block ${groupClass} ${isActive}`} onClick={() => selectBlock(_mission)}>
                                            <p>{_mission.objective}</p>
                                        </div>
                                        :
                                        <></>
                                    :
                                    <div ref={ref} style={{ fontSize, height: 55, width: 125 }} className={`mission-block ${groupClass} ${isActive}`} onClick={() => selectBlock(_mission)}>
                                        <p>{_mission.objective}</p>
                                    </div>
                                }
                            </span>
                        )
                    })}
                </div>
            </div>
        )
    };

    return (
        <>{group()}</>
    )

})

export default GroupView
