import React from 'react'
import { Item, Image, Dropdown } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react"

import "../styles/HomePage.scss"
import store from '../actions/ScoreStore.js';
import LogoFull from "../assets/logo-full.png";
import { rulesetOptions } from "../constants/MiscData.js"



const HomePage = observer(() => {

    const selectedMode = (e, { value }) => {
        // e.persist();
        store.rulesetId = value;
        if (store.players[0] !== undefined) {
            store.players[0].missionSelections = [
                {
                    id: 1,
                    objective: "PRIMARY OBJECTIVE",
                    type: "scores",
                    scores: ["5", "10", "15"]
                },
            ]
            store.players[0].faction = null;
        }
        if (store.players[1] !== undefined) {
            store.players[1].missionSelections = [
                {
                    id: 1,
                    objective: "PRIMARY OBJECTIVE",
                    type: "scores",
                    scores: ["5", "10", "15"]
                },
            ]
            store.players[1].faction = null;
        }
    };

    return (
        <main className="main-content home">
            <Image className="full-logo" src={LogoFull} size={'big'}></Image>

            <div className='ruleset-wrapper'>
                <h3>Select ruleset</h3>

                <Dropdown className='ruleset-selector main-dropdown'
                    placeholder='Select ruleset'
                    selection
                    value={store.rulesetId}
                    options={rulesetOptions}
                    onChange={selectedMode}
                />

            </div>
            <div className='start-wrapper'>
                <Item as={NavLink} to='/setup' className='start-game-button main-button'>Begin</Item>
                <div className='min-info'>Proceed to player setup!</div>
            </div>
        </main>


    )

})

export default HomePage