import React from 'react'
import { useState } from 'react';
import ConditionalMissionView from "../ConditionalMissionView"

const ManualInput = (props) => {
    const { missionData } = props;
    const [inputValue, setInputValue] = useState();
    const [errorMsg, setError] = useState();


    const setNewScore = (newInput) => {
        setInputValue(newInput);
        if (Number.isInteger(parseInt(newInput))) {
            setError();
            missionData.amount = Math.floor(newInput / missionData.divideBy);
        }
        else {
            setError('Wrong Input!')
            missionData.amount = 0;
        }
    }


    const ProgressView = () => {
        return (
            <>
                <div className='manual-input-field-wrapper'>
                    <input className='manual-input-field'
                        placeholder={'Troops killed..'}
                        type="text"
                        pattern={"[0-9]*"}
                        onChange={e => setNewScore(e.target.value)}
                    />
                </div>


                <div className='min-info'>{errorMsg}</div>
            </>
        )
    }

    return (
        <div className='manual-input-wrapper'>
            <ConditionalMissionView missionData={missionData} ProgressView={<ProgressView />}></ConditionalMissionView>
        </div>
    )
}

export default ManualInput