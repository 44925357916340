export const secondaryOne = {
    id: 1,
    groupName: "PURGE THE ENEMY",
    missions: [
        {
            id: 101,
            objective: "ASSASSINATE",
            type: "scores",
            scores: ["3", "6", "9", "12", "15"]
        },
        {
            id: 102,
            objective: "BRING IT DOWN",
            type: "scorerange",
            scoremin: 2,
            scoremax: 15
        },
        {
            id: 103,
            objective: "TITAN SLAYERS",
            type: "scores",
            scores: ["10", "15"]
        },
        {
            id: 104,
            objective: "SLAY THE WARLORD",
            type: "boss",
            endscore: 6,
            completed: false
        },
        {
            id: 105,
            objective: "CODEX WARFARE",
            type: "scorerange",
            scoremin: 1,
            scoremax: 15,
            faction: "Space Marines",
            roundsAvailable: [5]
        },
    ]
}

export const secondaryTwo = {
    id: 2,
    groupName: "NO MERCY, NO RESPITE",
    missions: [
        {
            id: 201,
            objective: "THIN THEIR RANKS",
            type: "manualinput",
            divideBy: 10
        },
        {
            id: 202,
            objective: "ATTRITION",
            type: "check",
            score: 4
        },
        {
            id: 203,
            objective: "WHILE WE STAND, WE FIGHT",
            type: "scores",
            scores: ["5", "10", "15"],
            roundsAvailable: [5]
        },
        {
            id: 204,
            objective: "FIRST STRIKE",
            type: "scores",
            scores: ["5", "8"],
            roundsAvailable: [0]
        },
        {
            id: 205,
            objective: "CODE OF COMBAT",
            type: "scores",
            scores: ["3", "6", "9", "12", "15"],
            faction: "Necron"
        },
        {
            id: 206,
            objective: "OATHS OF MOMENT",
            type: "scorerange",
            scoremin: 1,
            scoremax: 4,
            faction: "Space Marines"
        },
    ]
}

export const secondaryThree = {
    id: 3,
    groupName: "BATTLEFIELD SUPREMACY",
    missions: [
        {
            id: 301,
            objective: "ENGAGE ON ALL FRONTS",
            type: "scores",
            scores: ["2", "3"]
        },
        {
            id: 302,
            objective: "LINEBREAKER",
            type: "check",
            score: 4
        },
        {
            id: 303,
            objective: "DOMINATION",
            type: "check",
            score: 3
        },
        {
            id: 304,
            objective: "PURGE THE VERMIN",
            type: "scores",
            scores: ["2", "4", "6", "8"],
            faction: "Necron",
            roundsAvailable: [1, 2, 3, 4]
        },
        {
            id: 305,
            objective: "THE TREASURES OF AEONS",
            type: "scores",
            scores: ["2", "3", "5"],
            faction: "Necron"
        },
        {
            id: 306,
            objective: "SHOCK TACTICS",
            type: "check",
            score: 3,
            faction: "Space Marines"
        },
    ]
}

export const secondaryFour = {
    id: 4,
    groupName: "SHADOW OPERATIONS",
    missions: [
        {
            id: 401,
            objective: "INVESTIGATE SITES",
            type: "check",
            score: 3
        },
        {
            id: 402,
            objective: "REPAIR TELEPORT HOMER",
            type: "check",
            score: 5
        },
        {
            id: 403,
            objective: "RAISE THE BANNERS HIGH",
            type: "scorerange",
            scoremin: 1,
            scoremax: 6,
            roundsAvailable: [0, 1, 2, 3, 4, 5]
        },
        {
            id: 405,
            objective: "ANCIENT MACHINERIES",
            type: "scores",
            scores: ["3", "6", "9", "12", "15"],
            faction: "Necron"
        },
    ]
}

export const secondaryFive = {
    id: 5,
    groupName: "WARPCRAFT",
    missions: [
        {
            id: 501,
            objective: "MENTAL INTERROGATION",
            type: "check",
            score: 3
        },
        {
            id: 502,
            objective: "PSYCHIC RITUAL", //todo - per round, score only if ticked 3 times.
            type: "multicheck",
            score: 15,
            maxTimes: 3
        },
        {
            id: 503,
            objective: "ABHOR THE WITCH",
            type: "scorerange",
            scoremin: 3,
            scoremax: 15 //todo - p e na exo kavles, 3,5,6,9-15
        },
    ]
}

export const missionsCommon = {
    id: 101,
    groupName: "MISSION OBJECTIVES",
    missions: [
        {
            id: 1001,
            objective: "MINIMISE LOSSES",
            type: "scores",
            scores: ["5", "10", "15"],
            roundsAvailable: [5]
        },
        {
            id: 1002,
            objective: "VITAL GROUND",
            type: "scorerange",
            scoremin: 2,
            scoremax: 7,
            roundsAvailable: [1, 2, 3, 4, 5]
        },
        {
            id: 1003,
            objective: "SIPHON POWER",
            type: "scores",
            scores: ["1", "3", "6", "10"]
        },
        {
            id: 1004,
            objective: "SECURE NO MAN'S LAND",
            type: "scorerange",
            scoremin: 1,
            scoremax: 5
        },
        {
            id: 1005,
            objective: "RAZE",
            type: "scores",
            scores: ["5", "15"],
            roundsAvailable: [5]
        },
        {
            id: 1006,
            objective: "DATA INTERCEPT",
            type: "scorerange",
            scoremin: 1,
            scoremax: 6
        },
    ]
}

export const combinedDefault = [missionsCommon, secondaryOne, secondaryTwo, secondaryThree, secondaryFour, secondaryFive];