import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import "../../styles/MissionComponents.scss";
import ConditionalMissionView from "../ConditionalMissionView"


const ScoreRange = (props) => {
    const { missionData } = props;
    const [dropdownOptions, setDropdownOptions] = useState();

    useEffect(() => {
        var dropdown = [];
        dropdown.push({
            key: 0,
            text: '0',
            value: 0
        })
        for (var i = missionData.scoremin; i <= missionData.scoremax; i++) {
            dropdown.push({
                key: i,
                text: i,
                value: i
            })
        }
        setDropdownOptions(dropdown)
    }, [missionData]);

    const selectedMode = (e, { value }) => {
        // e.persist();
        missionData.amount = value;
    };

    const ProgressView = () => {
        return (
            <div className='score-range-selector-wrapper'>
                {dropdownOptions === undefined ?

                    <></>
                    :
                    <Dropdown className='score-range-selector main-dropdown'
                        placeholder='Score..'
                        selection
                        options={dropdownOptions}
                        onChange={selectedMode}
                    />
                }
            </div>
        )
    }

    return (
        <div className='score-range-wrapper'>
            <ConditionalMissionView missionData={missionData} ProgressView={<ProgressView />}></ConditionalMissionView>
        </div>
    )
}

export default ScoreRange