import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"
import Clock from "../components/Clock"
import "../styles/RoundPage.scss"
import store from '../actions/ScoreStore.js';
import { Icon } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";

import PlayerRoundView from '../components/PlayerRoundView';
import MissionView from '../components/MissionView';


const RoundPage = observer(() => {
    const [completed, setCompleted] = useState(true);
    const history = useHistory();

    const [showingPlayerOne, setVisiblePlayer] = useState(!store.playerTwoGoesFirst);
    const expand = () => setVisiblePlayer(!showingPlayerOne);

    
    useEffect(() => {
        store.pageContext = "round";
    }, []);



    if (store.gameFinished) {
        history.push("/endofgame");
    }

    return (
        <main className="main-content round">
            <Clock gameFinished={store.gameFinished}></Clock>
            <div className='round-data-wrapper'>
                <div className='current-round'>
                    <div>Current round: {store.currentRound + 1}</div>
                </div>
                <div className='player-wrapper desktop-only'>
                    {completed ?
                        <>
                            <div className='player-1 '>
                                <MissionView playerMissions={store.players[0].roundData[store.currentRound]}></MissionView>
                            </div>

                            <div className='player-2'>
                                <MissionView playerMissions={store.players[1].roundData[store.currentRound]}></MissionView>
                            </div>
                        </>
                        :
                        <div>Loading..</div>
                    }
                </div>
                <div className='player-wrapper mobile-only'>
                    <div className='swap-player-wrapper'>
                        {showingPlayerOne
                            ?
                            <>
                                <p className='player-two wh-cl'>Change to {store.players[1].playerName}</p>
                                <Icon className='player-two wh-cl' name='eye' onClick={() => expand()}></Icon>
                            </>
                            :
                            <>
                                <p className='player-one wh-cl'>Change to {store.players[0].playerName}</p>
                                <Icon className='player-one wh-cl' name='eye' onClick={() => expand()}></Icon>
                            </>
                        }
                    </div>

                    <div className='player-view'>

                        {completed ?
                            <>
                                <PlayerRoundView visible={showingPlayerOne} playerId={0}></PlayerRoundView>
                                <PlayerRoundView visible={!showingPlayerOne} playerId={1}></PlayerRoundView>
                            </>
                            :
                            <div>Loading..</div>
                        }
                    </div>

                </div>
            </div>
        </main>
    )
})
export default RoundPage