import React, { useState, useEffect } from 'react'
import { Dropdown, Item, Message } from 'semantic-ui-react'
import '../styles/SetupPage.scss';
import store from '../actions/ScoreStore.js';
import { observer } from "mobx-react"
import { NavLink } from "react-router-dom";

import { allMissionData } from "../constants/MissionUtils"
import GroupView from '../components/GroupView';

const factionOptions = [
    {
        key: 1,
        text: 'Necron',
        value: 'Necron',
    },
    {
        key: 2,
        text: 'Space Marines',
        value: 'Space Marines'
    },
    {
        key: 3,
        value: 'Other',
        text: 'Other'
    }
];

const PlayerChoices = observer((props) => {
    const { currentPlayer, setErrorMsg } = props;
    const allMissions = allMissionData();

    return (
        <div>
            <GroupView currentPlayer={currentPlayer} setErrorMsg={setErrorMsg} groupData={allMissions[1]} groupClass={"one"} ></GroupView>
            <GroupView currentPlayer={currentPlayer} setErrorMsg={setErrorMsg} groupData={allMissions[2]} groupClass={"two"} ></GroupView>
            <GroupView currentPlayer={currentPlayer} setErrorMsg={setErrorMsg} groupData={allMissions[3]} groupClass={"three"}></GroupView>
            <GroupView currentPlayer={currentPlayer} setErrorMsg={setErrorMsg} groupData={allMissions[4]} groupClass={"four"}></GroupView>
            <GroupView currentPlayer={currentPlayer} setErrorMsg={setErrorMsg} groupData={allMissions[5]} groupClass={"five"}></GroupView>
            <GroupView currentPlayer={currentPlayer} setErrorMsg={setErrorMsg} groupData={allMissions[0]} groupClass={"common"}></GroupView>
        </div>
    )
})

const SetupPage = observer(() => {
    const [errorMsg, setErrorMsg] = useState("");
    // const [currentPlayer, setCurrentPlayer] = useState(store.currentPlayer);

    useEffect(() => {
        store.pageContext = "setup";
    }, []);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const setPlayerName = (newValue) => {
        store.players[store.currentPlayer].playerName = newValue;
    }

    const handleDismiss = () => {
        setErrorMsg("")
    }

    const selectedMode = (e, { value }) => {
        // e.persist();
        store.players[store.currentPlayer].faction = value;
    };

    return (
        <main className="main-content setup">
            <div className='top-bar'>
                {errorMsg.length > 0 ?
                    <Message error content={errorMsg} onDismiss={() => handleDismiss()}></Message>
                    :
                    <></>
                }
                {store.currentPlayer === 0 ?
                    <>
                        <input type='text' placeholder='Player one..' onChange={e => setPlayerName(e.target.value)} value={store.players[store.currentPlayer].playerName}></input>
                    </>
                    :
                    <>
                        <input type='text' placeholder='Player two..' onChange={e => setPlayerName(e.target.value)} value={store.players[store.currentPlayer].playerName}></input>
                    </>
                }
                <Dropdown className='faction-selector main-dropdown'
                    placeholder='Select a Faction'
                    selection
                    value={store.players[store.currentPlayer].faction}
                    options={factionOptions}
                    onChange={selectedMode}
                    disabled={store.players[store.currentPlayer].missionSelections.length > 1}
                />
            </div>
            <div className='main-area'>
                <PlayerChoices setErrorMsg={setErrorMsg} currentPlayer={store.currentPlayer}></PlayerChoices>

            </div>

        </main>
    )
})

export default SetupPage
