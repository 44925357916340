import React, { useState, useEffect } from 'react'
import store from '../actions/ScoreStore.js';
import "../styles/EndOfGamePage.scss"
import { observer } from "mobx-react"
import MissionView from '../components/MissionView';


const PlayerView = (props) => {
    const { playerId, playerClass } = props;

    return (

        <div>
            <h2 className={`wh-cl ${playerClass}`}>{store.players[playerId].playerName}</h2>
            <h2 className={`wh-cl ${playerClass}`}>{store.players[playerId].faction}</h2>


            <div className='player-1'>
                <MissionView playerMissions={store.players[playerId].roundData[store.currentRound]}></MissionView>
            </div>

        </div>

    )
}



const EndOfGamePage = observer(() => {
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        store.currentRound = 5;
        store.pageContext = "endofgame";
        let setupCompleted = setupMissions();

        setCompleted(setupCompleted);
    }, []);

    const setupMissions = () => {

        let newSelections1 = [];
        store.players[0].missionSelections.forEach(function (_selection) {
            var obj = {}
            Object.assign(obj, _selection);

            obj.amount = 0;
            obj.currentPlayer = 0;
            obj.currentRound = store.currentRound;
            if (obj.roundsAvailable && obj.roundsAvailable.length > 0) {
                newSelections1.push(obj)
            }
        })

        let newSelections2 = [];
        store.players[1].missionSelections.forEach(function (_selection) {
            var obj = {};
            Object.assign(obj, _selection);

            obj.amount = 0;
            obj.currentPlayer = 0;
            obj.currentRound = store.currentRound;
            if (obj.roundsAvailable && obj.roundsAvailable.length > 0) {
                newSelections2.push(obj)
            }
        })

        if (store.players[0].roundData[store.currentRound] === undefined) {
            //should never get to this point in this page. required until 'back' feature is implemented
            store.players[0].roundData.push(newSelections1);
        } else {
            store.players[0].roundData[store.currentRound] = newSelections1
        }

        if (store.players[1].roundData[store.currentRound] === undefined) {
            //should never get to this point in this page. required until 'back' feature is implemented
            store.players[1].roundData.push(newSelections2);
        } else {
            store.players[1].roundData[store.currentRound] = newSelections2
        }

        return true;
    }


    return (
        <main className="main-content endgame">
            <h1>End game scoring</h1>
            <div className='confirm-players-wrapper'>
                {completed ?
                    <>
                        <PlayerView playerId={0} playerClass="one"></PlayerView>
                        <PlayerView playerId={1} playerClass="two"></PlayerView>
                    </>
                    :
                    <div>Loading..</div>
                }
            </div>

        </main>


    )

})

export default EndOfGamePage