import React, { useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import ConditionalMissionView from "../ConditionalMissionView"

const Check = (props) => {
    const { missionData } = props;
    const [isChecked, setChecked] = useState(false);

    const missionCompleted = () => {
        setChecked(!isChecked)
        if (isChecked) {
            missionData.amount = 0;
        }
        else {
            missionData.amount = missionData.score;
        }

    }

    const ProgressView = () => {
        return (
            <div className='check-selector-wrapper'>
                <Checkbox className='checkbox-field'
                    checked={isChecked}
                    onChange={missionCompleted}
                ></Checkbox>
            </div>
        )
    }


    return (
        <div className='check-wrapper'>
            <ConditionalMissionView missionData={missionData} ProgressView={<ProgressView />}></ConditionalMissionView>
        </div>
    )
}

export default Check